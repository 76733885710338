@import "../../variables.scss";

.header {
  background-color: #edeef0 !important;
  color: black !important;
  box-shadow: unset !important;

  .toolbar {
    width: 100%;
    max-width: 1920px;
    height: $header-height;
    margin: auto;
    justify-content: space-between;
    background: white;
    box-shadow: 0 1px 0 0 #d7d8db, 0 0 0 1px #e3e3e3;
  }

  .logo-block {
    cursor: pointer;

    .logo {
      height: 100%;
      pointer-events: none;
      max-height: 125px;
    }
  }

  .phone-block {
    display: flex;
    align-items: center;

    svg {
      color: $blue-color;
      font-size: 16px;
      margin-top: 2px;
    }

    a {
      display: block;
      font-size: 18px;
      font-weight: 700;
      color: $blue-color;
      line-height: 34px;
      margin-left: 15px;
      height: 34px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1168px) {
  .header {
    .toolbar {
      height: 64px;
      max-width: 100%;

      svg {
        color: $blue-color;
        font-size: 24px;
      }

      .logo-block img {
        position: absolute;
        left: 70px;
        top: 10px;
        max-height: 40px;
      }
    }
  }
}

@media (max-width: 576px) {
  .header {
    .toolbar {
      .logo-block img {
        left: 50px;
      }

      .phone-block {
        display: none;
      }
    }
  }
}
