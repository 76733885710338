.about-us {
  padding: 40px;
  padding-left: 0;
  background-color: #fafafa;
  width: 600px;

  h1 {
    margin-bottom: 40px;
  }

  .about-us-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 1200px) {
  .about-us {
    width: 50vw;
  }
}

@media (max-width: 1168px) {
  .about-us {
    width: 100%;
    padding: 24px !important;
  }
}

@media (max-width: 600px) {
  .about-us {
    h1 {
      margin-bottom: 20px;
    }

    .about-us-text {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
