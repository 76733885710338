@import "../../variables.scss";

.MuiDialog-paper {
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 !important;
  padding: 40px;

  h2,
  p,
  label,
  button {
    font-family: $theme-font !important;
  }

  .MuiFormControl-root {
    margin-bottom: 20px;
    margin-top: 10px;

    .MuiOutlinedInput-root {
      border-radius: 0 !important;
    }
  }

  .MuiDialogTitle-root {
    padding: 0 24px;
  }

  .request-dialog-title {
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 0;
    font-size: 24px;
  }

  .request-dialog-description {
    font-size: 16px;
    display: block;
  }

  .request-dialog-action-button {
    text-transform: lowercase;
    border-radius: 0;
    font-family: $theme-font !important;
    height: 50px;
    font-size: 16px;
    width: 100%;
    margin: 0 16px;
    background-color: $blue-color;
    color: white;

    &:hover,
    &:active {
      background-color: white;
      color: $blue-color;
    }
  }

  .MuiDialogActions-root {
    margin-top: -10px;
  }
}

@media (max-width: 600px) {
  .MuiDialog-paper {
    max-width: 100vw !important;
    width: 100vw;
    margin: 0 16px !important;
    padding: 15px 0;
  }
}
