@import "../../Variables.scss";

.admin {
  .backgrounds-tab {
    height: 100%;
    overflow: auto;

    .scrollbar-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    img {
      max-width: 160px;
      margin: 5px;
    }

    img.selected {
      box-shadow: 0 0 0px 5px $theme-color;
    }
  }
}
