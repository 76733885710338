@import "../../Variables.scss";

.settings-container {
  transition: unset !important;
  max-width: 100vw;

  .logout-button {
    position: absolute;
    right: 24px;
    top: 6px;

    button {
      position: relative;
      z-index: 10;
      border-color: #232323;
      color: $theme-color;
      background-color: #232323;

      svg {
        color: rgba(255, 255, 255, 0.75);
      }

      &:hover {
        background-color: #292929;

        svg {
          color: $theme-color;
        }
      }
    }
  }

  .ant-tabs {
    height: 460px;
    width: calc(100vw - 48px);
    max-width: 700px;
    max-height: calc(100vh - 79px);

    &-content {
      height: 100%;
    }

    &-ink-bar {
      background: $theme-color;
    }

    &-tab {
      &-active {
        font-weight: bold !important;
        color: $theme-color !important;
      }

      &:hover {
        color: $theme-color !important;
      }
    }
  }

  .ant-card-body {
    header {
      background: #232323;
      height: 45px;
      cursor: move;
      padding-left: 16px;
      margin-top: -25px;
      margin-left: -25px;
      margin-bottom: 10px;
      width: calc(100% + 50px);

      h3 {
        line-height: 45px;
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
}

@media (max-width: 600px) {
  .settings-container {
    .ant-tabs {
      height: calc(100vh - 79px);
    }

    .ant-divider {
      display: none !important;
    }

    .scrollbar-container,
    .ck-content {
      height: calc(100vh - 190px) !important;
    }

    .partners-tab {
      .scrollbar-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

        .partner {
          margin: 0 0 20px;
          width: 120px;
          height: unset;
        }
      }
    }

    .general-tab {
      .scrollbar-container {
        height: calc(100vh - 140px) !important;
      }

      .logo-row {
        flex-direction: column;
        align-items: start;

        img {
          margin: 0 0 20px;
        }

        h3 {
          display: none;
        }
      }
    }

    .backgrounds-tab {
      .scrollbar-container {
        height: calc(100vh - 80px) !important;

        img {
          max-width: calc(50vw - 35px);
        }
      }
    }
  }
}
