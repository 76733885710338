@import "../../Variables.scss";

.about-us-tab {
  .ck-content {
    height: 300px;
    transition: 350ms;

    &:focus {
      border: 1px solid $theme-color !important;
    }
  }

  .ck.ck-editor {
    width: 100%;
  }

  .ck.ck-list__item .ck-button.ck-on {
    background: $theme-color !important;
  }
}
