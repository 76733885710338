@import "../../variables.scss";

.contacts {
  padding: 40px;
  background-color: $blue-color;
  height: 100%;
  width: 600px;

  h1 {
    color: white;
    margin-bottom: 40px;
  }

  .contacts-text {
    font-size: 18px;
    line-height: 28px;
    color: white;

    p {
      margin-bottom: 0;
    }

    .contacts-titles {
      font-weight: 600;
      margin-bottom: 40px;
    }

    .contacts-addresses {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1200px) {
  .contacts {
    width: 50vw;
  }
}

@media (max-width: 1168px) {
  .contacts {
    width: 100%;
    padding: 24px 24px 48px;
  }
}

@media (max-width: 600px) {
  .contacts {
    h1 {
      margin-bottom: 20px;
    }

    .contacts-text {
      font-size: 14px;
      line-height: 20px;

      .contacts-titles {
        margin-bottom: 20px;
      }

      .contacts-addresses {
        margin-bottom: 10px;
      }
    }
  }
}
