.settings-edit-form {
  .ant-upload {
    transform: translateY(-3px);
  }

  .upload-icon {
    max-width: 100px;
    max-height: 100px;
  }

  .delete-button {
    position: absolute;
    right: 0;
  }
}
