@import "../../variables.scss";

$circle-size: 730px;

.jumbotron {
  position: relative;
  padding: 60px 0 300px;

  h1 {
    position: relative;
    z-index: 1;
    width: 800px;
  }

  .request-button {
    text-transform: lowercase;
    background-color: $blue-color;
    border-radius: 0;
    font-family: $theme-font !important;
    width: 225px;
    height: 50px;
    font-size: 16px;

    &:hover,
    &:active {
      background-color: $blue-color;
    }
  }

  .orange-circle {
    transition: 350ms;
    position: absolute;
    display: flex;
    align-items: flex-end;
    width: $circle-size;
    height: $circle-size;
    background-color: $orange-color;
    border-radius: 50%;
    right: -350px;
    top: -100px;

    img {
      width: 600px;
      transition: 350ms;
    }
  }
}

@media (max-width: 1920px) {
  .jumbotron {
    .orange-circle {
      img {
        width: 500px;
      }
    }
  }
}

.jumbotron {
  .orange-circle {
    @media (max-width: 1820px) {
      right: -300px;
    }
    @media (max-width: 1720px) {
      right: -250px;
    }
    @media (max-width: 1620px) {
      right: -200px;
    }
    @media (max-width: 1520px) {
      right: -150px;
    }
  }
}

@media (max-width: 1280px) {
  .jumbotron {
    .orange-circle {
      right: -100px;

      img {
        width: 400px;
      }
    }
  }
}

@media (max-width: 1168px) {
  .jumbotron {
    padding: 40px 0 150px;

    h1 {
      max-width: 100%;
    }

    .orange-circle {
      width: $circle-size * 0.8;
      height: $circle-size * 0.8;
      right: -100px;
      top: 0;

      img {
        width: 60%;
      }
    }
  }
}

@media (max-width: 960px) {
  .jumbotron {
    h1 {
      padding-top: 30px;
    }

    .orange-circle {
      width: $circle-size * 0.6;
      height: $circle-size * 0.6;
      right: -170px;
      top: 50px;
    }
  }
}

@media (max-width: 768px) {
  .jumbotron {
    padding: 40px 0;

    h1 {
      padding-top: 0;
      font-size: 60px;
      line-height: 80px;
    }

    .orange-circle {
      display: none;
    }

    .request-button {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .jumbotron {
    padding: 20px 0 40px;

    h1 {
      padding-top: 0;
      font-size: 32px;
      line-height: 48px;
    }
  }
}
