@import "../../variables.scss";

.partners {
  h1 {
    margin-bottom: 60px;
  }

  .partners-list {
    display: flex;

    .partner-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      height: calc(1200px / 5);
      padding: 40px;
      transition: 350ms;

      img {
        max-height: 120px;
        max-width: 160px;
        opacity: 0.8;
        transition: 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover {
          opacity: 1;
          transform: translate(0, -5px);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .partners {
    .partners-list {
      .partner-item {
        width: 20%;
        height: 20vw;
        padding: 40px;

        img {
          max-height: 120px;
          max-width: 100px;
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .partners {
    .partners-list {
      flex-direction: column;
      align-items: center;

      .partner-item {
        width: 40%;
        height: 20vw;
        padding: 40px;
        transition: 350ms;
        margin-bottom: 40px;

        img {
          max-height: 120px;
          max-width: 200px;
        }
      }
    }
  }
}
