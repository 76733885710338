.services-tab {
  .service {
    width: 55px;
    height: 100%;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 12px;
  }

  .scrollbar-container {
    height: 300px;
  }
}
