section.not-found {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-empty-description {
    white-space: pre-line;
  }
}
