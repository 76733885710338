.map {
  position: relative;
  max-width: 1920px;
  margin: auto;

  img {
    width: 100vw;
    max-width: 1920px;
  }

  span {
    position: absolute;
    background-color: orange;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
}

.map-note {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: white;
  font-size: 10px;
  white-space: pre-wrap;
  z-index: 3;

  b {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .map span {
    width: calc(100vw * 0.0125);
    height: calc(100vw * 0.0125);
  }
}

@media (max-width: 768px) {
  .map {
    display: none;

    // .map-note {
    //   display: none;
    // }
  }

  // .fslightbox-source-inner {
  //   position: relative;
  // }
}

// @media (max-width: 600px) {
//   .map-note {
//     font-size: 8px;

//     b {
//       font-size: 10px;
//     }
//   }
// }
