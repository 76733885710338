.advantages {
  h1 {
    margin-bottom: 100px;
  }

  .advantages-list {
    display: flex;
    flex-wrap: wrap;

    .advantage-item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 90px;

      img {
        width: 85px;
      }

      .advantage-description {
        font-size: 22px;
        line-height: 36px;
        transition: 350ms;
        font-weight: 600;
        margin-left: 40px;
        max-width: 350px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .advantages {
    .advantages-list {
      flex-direction: column;

      .advantage-item {
        width: 100%;
        margin-bottom: 70px;

        .advantage-description {
          margin-left: 24px;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .advantages {
    h1 {
      margin-bottom: 40px;
    }

    .advantages-list {
      .advantage-item {
        margin-bottom: 50px;

        img {
          width: 70px;
        }

        .advantage-description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
