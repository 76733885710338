@import "./variables.scss";

body {
  margin: 0;
  font-family: $theme-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: #edeef0;

  &.white {
    background: white;
  }
}

a {
  text-underline-position: under;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.my-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
