@import "../../variables.scss";

.footer {
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1920px;
    height: $header-height;
    border-top: 1px solid #e5e5e5;
    margin: auto;
  }

  .rights-reserved {
    height: 80px;
    text-align: center;
    font-size: 14px;
    color: #636363;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;

    .toolbar {
      height: unset;

      .navigation {
        margin: 24px;
        display: flex;
        flex-direction: column;
        text-align: center;

        a {
          display: block;
          line-height: 48px;
        }
      }
    }

    .rights-reserved {
      height: 80px;
      text-align: center;
      font-size: 14px;
      color: #636363;
    }
  }
}
