@import "../../variables.scss";

.services {
  h1 {
    margin-bottom: 100px;
  }

  .services-items {
    display: flex;
    flex-wrap: wrap;

    .service-item {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      height: 60px;
      width: 50%;
      margin-bottom: 170px;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }

      .service-description {
        font-size: 22px;
        line-height: 36px;
        transition: 350ms;
        font-weight: 600;
        margin-left: 40px;
        max-width: 250px;
      }

      &:hover {
        &::before {
          background-color: rgba(#01467b, 0.75);
        }

        .service-description {
          color: $blue-color;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        width: 140px;
        height: 140px;
        bottom: 0;
        border-radius: 50%;
        background-color: transparent;
        transition: 350ms;
      }
    }
  }
}

.ant-popover {
  &-arrow {
    display: none !important;
  }

  &-inner {
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1) !important;
  }

  &-inner-content {
    white-space: pre-wrap;
    border-radius: 0 !important;
    padding: 20px 30px !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    color: black !important;
  }
}

@media (max-width: 1024px) {
  .services {
    .services-items {
      flex-direction: column;

      .service-item {
        margin-bottom: 120px;
        width: 100%;

        .service-description {
          max-width: unset;
          margin-left: 24px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .services {
    h1 {
      margin-bottom: 40px;
    }

    .services-items {
      .service-item {
        margin-bottom: 40px;

        img {
          width: 70px;
          height: 70px;
        }

        &::before {
          top: -5px;
          width: 70px;
          height: 70px;
        }

        .service-description {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0;
          flex: 1;
        }
      }
    }
  }

  .ant-popover {
    &-inner-content {
      padding: 15px !important;
      font-size: 14px;
      line-height: 28px;
    }
  }
}
