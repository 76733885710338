.login-form {
  $form-controls-height: 35px;
  display: flex;
  flex-direction: column;

  &-input {
    display: flex;
  }

  &-message {
    padding-left: 55px;
    margin-top: 5px;
    height: 30px;
    color: rgba(255, 255, 255, 0.9);
  }

  input,
  button {
    border: 0;
    height: $form-controls-height;
    outline: none;
    color: #f5f5f5;
    font-size: 16px;
  }

  input {
    width: calc(#{$form-controls-height} * 6);
    border-radius: calc(#{$form-controls-height} / 2);
    margin-right: 8px;
    padding-left: calc(#{$form-controls-height} / 2);
    transition: 350ms ease-in-out;

    &[type="password"] {
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .submit-button {
    width: $form-controls-height;
    height: $form-controls-height;

    .anticon:not(.indicator) {
      width: $form-controls-height;
      height: $form-controls-height;
      position: absolute;
      opacity: 1;
      transition: opacity 350ms ease-in-out;

      &.hidden {
        opacity: 0;
      }

      svg {
        font-size: 20px;
        height: $form-controls-height;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .indicator {
      width: $form-controls-height;
      height: $form-controls-height;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: green;
        height: 20px;
        width: 20px;
      }
    }

    [type="button"] {
      width: $form-controls-height;
      height: $form-controls-height;
      z-index: 10;
      border: 0;
      position: relative;
      background: none;
      cursor: pointer;
    }
  }
}
