@import "../../variables.scss";

.navigation {
  a {
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin: 0 20px;

    &:hover {
      color: $blue-color;
    }
  }
}

.ant-drawer {
  .ant-menu {
    margin-top: 5px !important;
  }

  .ant-menu-item a {
    margin: 0 !important;
    font-size: 14px;
  }

  .ant-drawer-content-wrapper {
    padding-top: 64px;
  }

  .ant-drawer-body {
    padding: 0 !important;

    nav {
      a {
        display: block;
        margin: 12px 0;
      }
    }
  }

  .ant-drawer-header {
    padding: 16px !important;

    .ant-drawer-title {
      font-weight: 600;
      font-size: 14px !important;
    }
  }
}
