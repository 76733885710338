@import "./Variables.scss";

.admin {
  height: 100vh;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: url("./assets/backgrounds/mojave-night.jpg") no-repeat
    center/cover;
}

.admin-button {
  border-color: $theme-color !important;
  background-color: $theme-color !important;

  &.secondary {
    border-color: $theme-color !important;
    background-color: white !important;
    color: $theme-color !important;
  }

  &[disabled] {
    background-color: whitesmoke !important;
    border-color: whitesmoke !important;
    color: #232323 !important;
  }
}
