.advantages-tab {
  .advantage {
    width: 55px;
    height: 100%;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 12px;
  }

  .scrollbar-container {
    height: 300px;
  }
}
