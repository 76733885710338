@import "../variables.scss";

.content {
  width: $container-width;
  margin: auto;

  > div {
    margin-bottom: 80px;
  }

  h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 95px;
  }

  &.content-first {
    padding-top: $header-height;
  }
}

.content-fluid {
  background: linear-gradient(to right, #fafafa 50%, $blue-color 50%);
  margin-bottom: 80px;

  .content {
    display: flex;

    > div {
      margin-bottom: 0;
    }
  }
}

.wrapper {
  max-width: 1920px;
  margin: auto;
  background: white;
  box-shadow: 0 1px 0 0 #d7d8db, 0 0 0 1px #e3e3e3;
  overflow: hidden;
}

.backtop {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: $blue-color;
  text-align: center;
  font-size: 14px;
  color: white;
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  .content {
    max-width: 100%;
    padding: 0 24px;

    > div {
      margin-bottom: 40px;
    }

    h1 {
      font-size: 60px;
      line-height: 80px;
    }
  }
}

@media (max-width: 1168px) {
  .content {
    &.content-first {
      padding-top: 64px;
    }
  }

  .content-fluid {
    background: transparent;

    .content {
      flex-direction: column;
      padding: 0;
    }
  }
}

@media (max-width: 768px) {
  .content {
    h1 {
      font-size: 48px;
      line-height: 60px;
    }
  }
}

@media (max-width: 576px) {
  .content {
    padding: 0 12px;

    h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
