@import "../../Variables.scss";

.contacts-tab {
  .ant-input {
    &:focus,
    &:hover {
      border-color: $theme-color;
    }
  }
}
